<template>
  <div>
    <el-dialog title="修改试卷信息"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="460px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷时长">
              <el-input clearable
                        placeholder="试卷时长（分钟）"
                        style="width:100%"
                        v-model="form.paper_duration" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>
        </el-row>
        <p>
          说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>

        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="save">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      subjectKey: 'subject_id',
      form: {},
      dialogVisible: false,
      rules: {
      },

    }
  },
  mounted () {

  },
  methods: {
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      let form = {
        edu_paper_id: this.form.edu_paper_id,
        paper_name: this.form.paper_name,
        paper_duration: this.form.paper_duration,
        end_time: this.form.end_time,
        paper_time: this.form.paper_time
      }
      this.$http({
        url: '/api/v1/combination/whole_edit',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.refresh()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>