<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="20">
        <el-button type="primary"
                   @click="toAdd">
          新增
        </el-button>
        <el-button type="primary"
                   @click="toZhiding">
          指定学生
        </el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <addForm ref="addFormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :isInit="false"
                :now-size.sync="size"
                :total="totalElements" />
    <editForm ref="editRef" />

  </div>
</template>

<script>
import addForm from './components/formDialog.vue'
import editForm from '../components/editForm.vue'
import { getTestList } from '@/api/IntelligentTest'
import { checkTime } from '@/utils/date.js'
import { sheetUrl } from '@/utils/basicUrl.js'
import { getSSOUrl } from '@/api/gd.js'

export default {
  data () {
    return {
      userInfo: [],
      tableData: [],
      page: 1,
      is_research: Number(window.localStorage.getItem('is_research')),
      size: 10,
      totalElements: 0,
      // showList: [],
      tableLoading: false,
      pageName: 'cz_intelligent_admin',
      tableColumns: [
        { prop: 'edu_paper_id', align: 'center', label: 'ID', width: '100', showToopic: false },
        { prop: 'paper_name', align: 'center', label: '试卷名称', width: '120', showToopic: true },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        { prop: 'paper_district_desc', align: 'center', label: '试卷地区', width: '', showToopic: false },
        { prop: 'knowledge', align: 'center', label: '考点/章节名称/核心素养', width: '240', showToopic: true, isStatus: true },
        { prop: 'paper_score', align: 'center', label: '总分', width: '100', showToopic: false },
        { prop: 'paper_time', align: 'center', label: '开始时间', width: '', showToopic: false, sortable: true },
        { prop: 'end_time', align: 'center', label: '截止时间', width: '', showToopic: false, sortable: true },
        // { prop: 'show_to_desc', align: 'center', label: '可见性', width: '140', showToopic: true, },
        { prop: 'paper_enable', align: 'center', label: '是否提交', width: '', showToopic: false, isStatus: true },
        { prop: 'sheet_status', align: 'center', label: '答题卡状态', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'left', label: '操作', width: '320', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '制作答题卡', type: 'export', event: 'exportCard' },

            { content: '面批', event: 'toCorrectPaper', isBtnStatus: true },
            { content: '收藏', event: 'collect', type: "collect" },
            { content: '删除', type: 'del', event: 'delRow' },

          ]
        },
      ]
    }
  },
  components: {
    addForm, editForm
  },
  mounted () {

    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    // console.log('sss', this.userInfo);
    this.initData()
    this.setTablesColums()
  },

  methods: {
    collect (row) {
      console.log(row)
      // return
      this.$http({
        url: '/api/v1/combination/collect',
        method: 'post',
        data: {
          edu_paper_id: row.edu_paper_id
        }
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },

    getIconType (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return 'collect'
        } else {
          return 'cancelCollect'
        }
      }
    },
    getBtnStatus (opItem, row) {
      // 是否显示【修改批改类型】按钮
      if (opItem.event == "editChange") {
        if (row['correct_type'] == 1 && row['paper_enable'] == 1 && this.userInfo.school_data && this.userInfo.school_data.change_correct_type == 1) {
          return true
        } else {
          return false
        }
      }
      if (opItem.event == "delRow") {
        if (row['can_del']) {
          return true
        } else {
          return false
        }
      }
      if (opItem.event.indexOf("jumpGd") != -1) {
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        if (Number(userInfo['is_admin']) == 1 || [745, 646, 749, 754, 720, 774].find(value => value === Number(userInfo['school_data']['school_id']))) {
          return true
        } else {
          return false
        }
      }
    },

    jumpGd () {
      getSSOUrl('sso').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },
    jumpGd2 () {
      getSSOUrl('review').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },

    getIconContent (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return '收藏'
        } else {
          return '取消收藏'
        }
      }
    },
    getClass (prop, value, row) {
      if (prop == 'knowledge') {
        return 'shenlue'
      }
      return value ? 'pass' : 'notpass'
    },
    getValue (prop, value, row) {
      if (prop == 'knowledge') {
        let result = "";
        if (row.check_point_2_desc) {
          result += "二级考点：" + row.check_point_2_desc + " ";
        }
        if (row.capability_desc) {
          result += "核心素养：" + row.capability_desc + " ";
        }
        if (row.knowledge_desc) {
          result += "考点:" + row.knowledge_desc + " ";
        }
        if (row.chapter_desc) {
          result += "章节:" + row.chapter_desc;
        }
        return result.trim(); // 使用trim()去除末尾多余的空格
      }
      if (prop == 'paper_enable') {
        return value ? '已提交' : '未提交'
      }
      if (prop == 'is_collect') {
        return value ? '是' : '否'
      }

      if (prop == 'sheet_status') {
        //"关联答题卡数据状态"  0 未关联  -1 取消申请 1 制作中  2 制作完成，未确定 3 已生成 4 申请中",
        switch (value) {
          case 0: return "未关联";
          case -1: return "取消申请";
          case 1: return "制作中";
          case 2: return "制作完成，未确定";
          case 3: return "已生成";
          case 4: return "申请中";
          default: break;
        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    editChange (row) {
      this.$confirm("确定修改试卷批改类型？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/combination/update_paper_correct',
          method: 'post',
          data: {
            edu_paper_id: row.edu_paper_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.refresh()
        })
      }).catch(() => { })
    },
    toAdd () {
      let today = new Date()
      let year = today.getFullYear()
      let form1 = {}
      let form = {}
      /** 获取上次提交数据
       * 1.根据接口获取上次提交数据，
       * 2.在表单页面获取下拉数据时需判断是否有初始数据，否则默认为第一选项（需注意字段名是否相同）
       * 3.修改数据时，关联数据变化的需判断已选数据是否还存在，只保留还存在的
       * 4.新增组卷不提交可见性数据，若默认数据为指定学生页面提交的则含有该数据，应删除
       */
      this.$http({
        url: '/api/v1/public/get_record',
        method: 'get',
        params: {
          module_id: 7
        }
      }).then(res => {
        if (res.data) {
          form1 = res.data.list
        }

        form = {
          name: form1.name || '',
          // questionDistrict: form1.questionDistrict.split(',').map(item => Number(item)),
          questionSubject: form1.subject_id || '',
          // source_id: form1.source_id || '',
          questionType: [],
          questionCategory: [],
          knowledge_id: form1.knowledge_id || [],
          check_point_id: form1.checkpoints || [],
          question_year_begin: form1.question_year_begin || (year - 1) + '',
          question_year_end: form1.question_year_end || year + '',
          paper_name: form1.paper_name || '',
          checkpoints2: form1.checkpoints2 || [],
          questionNumber: 5,
          paper_time: form1.paper_time || '',
          edu_paper_type: form1.edu_paper_type || '',
          grade_id: form1.given_garde || '',
          class_id: form1.class_id || '',
          paper_duration: form1.paper_duration || '',
          question_difficulty: form1.question_difficulty || '',
          end_time: form1.end_time || '',
          edu_paper_correct_type: form1.edu_paper_correct_type || '1',
          select_which: form1.select_which || '',
          textbook_id: form1.textbook_id || '',
          district_id: form1.district_id || '',
          chapter_ids:form1.chapter_id || ''

        }
        if (form1.questionDistrict && !Array.isArray(form1.questionDistrict)) {
          form.questionDistrict = [form1.questionDistrict]
        } else {
          form.questionDistrict = form1.questionDistrict || []
        }
        if (form1.source_id && !Array.isArray(form1.source_id)) {
          form.source_id = [form1.source_id]
        } else {
          form.source_id = form1.source_id || []
        }
        if (form.end_time && !checkTime(form.end_time, 1)) {
          form.end_time = ''
        }
        // console.log(' form1.questionDistrict', form1.questionDistrict.split(','))
        this.$refs.addFormRef.form = form
        // this.$refs.addFormRef.getDistrictList()
        this.$refs.addFormRef.dialogVisible = true
      })
    },

    async initData (page, limit) {
      if (page) {
        this.page = page
      }
      if (limit) {
        this.size = limit
      }
      let params = {
        page: this.page,
        limit: this.size
      }
      // 获取智能组卷列表
      const { data } = await getTestList(params)
      this.tableData = data.list
      this.totalElements = data.count
    },
    toInfo (row) {
      this.$router.push('/testPapercomposition/IntelligentTest/info?&type=smart&edu_paper_id=' + row.edu_paper_id)
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/combination/del_edu_paper',
          method: 'post',
          data: {
            edu_paper_id: row.edu_paper_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    editRow (row) {
      this.$refs.editRef.form = row
      this.$refs.editRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    toCorrectPaper (row) {
      this.$router.push('/correctPaperCard?edu_paper_id=' + row.edu_paper_id)
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    toZhiding () {
      this.$router.push('/testPapercomposition/IntelligentTest/assign')
    },
    exportCard (row) {
      //this.$router.push(`/testPapercomposition/makeSheet?id=${row.edu_paper_id}&sheet_id=${row.sheet_id}&status=${row.sheet_status}&correct_type=0`)
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      window.open(`${sheetUrl}/#/?czb=1&paper_id=${row.edu_paper_id}&cannot_edit=1&t=${userInfo.token}&rt=${userInfo.refresh_token}`, '_blank')
    },
    // templateCard (row) {
    //   this.$router.push(`/testPapercomposition/templateCard?id=${row.edu_paper_id}&sheet_id=${row.sheet_id}&status=${row.sheet_status}&correct_type=0`)

    // },
    exportEditCard (row) {
      this.$router.push(`/testPapercomposition/makeSheet?id=${row.edu_paper_id}&sheet_id=${row.sheet_id2}&status=${row.sheet_status2}&correct_type=1`)

    }
  },

  beforeRouteEnter (to, from, next) {
    if (from.path == '/testPapercomposition/makeSheet') {
      next(vm => {
        //因为当钩子执行前，组件实例还没被创建
        // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
        console.log(vm);//当前组件的实例
        vm.refresh()
      });
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>