<template>
  <div>
    <el-dialog title="新增智能组卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="960px"
               :before-close="handleClose">
      <paperForm ref="PaperFormRef"
                 v-if="dialogVisible"
                 key='add'
                 @validate="toDetails"
                 :isShow_to="isShow_to"
                 :showClass="true" />
    </el-dialog>
  </div>
</template>

<script>
import paperForm from './form.vue'
import { createPaper } from '@/api/IntelligentTest.js'
export default {
  data () {
    return {
      dialogVisible: false,
      isShow_to: false,
      form: {}
    }
  },
  created () {
    // this.getDistrictList()
  },
  components: { paperForm },

  mounted () {

  },
  watch: {
    dialogVisible: {
      handler (newVal, oldVal) {
        if (newVal) {

          this.$nextTick(() => {
            // this.$refs.PaperFormRef.form = this.form
            this.$set(this.$refs.PaperFormRef, 'form', this.form)
            this.$refs.PaperFormRef.getSubjectList()
            setTimeout(() => {
              this.$refs.PaperFormRef.initData()
            }, 500)

          })

        }
      }
    }
  },
  methods: {
    async toDetails (form) {
      try {
        const res = await createPaper(form)
        this.$refs.PaperFormRef.loadings = false
        if (res.status == 400) {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning'
          });
        } else {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.handleClose()
          this.$router.push(`/testPapercomposition/IntelligentTest/info?type=smart&edu_paper_id=${res.data.edu_paper_id}`)
        }
      } catch (err) {
        this.$refs.PaperFormRef.loadings = false
        // 
        console.log(err)

      }
    },
    handleClose () {
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
.question_number {
  position: relative;

  .question_number_select {
    padding: 12px 12px 8px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    box-shadow: 0px 2px 12px #ddd;
    position: absolute;
    z-index: 1;
    width: max-content;
    max-width: 350px;
    text-align: right;
  }
  .question_number_arrow {
    z-index: 2;
    position: absolute;
    display: block;
    border-bottom: 8px solid #dfdfdf;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: 39px;
    left: 20px;
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-bottom: 8px solid #ffffff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: 1px;
      left: -8px;
    }
  }
}
.year_component {
  margin-top: 10px;
  position: absolute;
  z-index: 999;
}
</style>